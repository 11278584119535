import { useState, useEffect } from "react";
import { ListItemIcon, ListItem, List, Grid, Typography, IconButton, MenuItem, InputAdornment, CircularProgress, Link, AppBar, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import WcIcon from '@mui/icons-material/Wc';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { RegistrationRequest, Country, ExternalRegistrationRequest, AuthenticationResponse, ExternalAuthenticationType, ExternalAuthenticationRequest } from "orderme-api-integration-client";
import { CustomerClient } from "../../../helpers/client";
import { Success } from "./success";
import { openRules } from "../../policy/rulesDownloader";
import { ErrorGrid, RegisterButton } from "./registerStyles";
import { RegisterFieldValues, RegistrationProps } from "../../../interfaces/interfaces";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { PhonePrefixSelection } from "./phonePrefixSelection";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CakeIcon from '@mui/icons-material/Cake'
import { InputField } from "../../inputField/inputField";
import { CheckBox } from "../../checkBox/checkBox";
import { AxiosResponse } from "axios";
import { getStatusError } from "../../../helpers/errorMessage";
import { Gender, genderItems, getGenderLabel, getGenderType } from "../../../helpers/genderItems";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from "moment";
import { useAppDispatch } from "../../../redux/hooks";
import { AppStyle } from "../../../helpers/appStyle";
import { appStyleMode } from "../../../helpers/clientConfigs";
import LoadingButton from '@mui/lab/LoadingButton';
import { BreadcrumbsBar } from "../../breadcrumbs/breadcrumbsBar";
import { authenticate, authenticateFromApple, authenticateFromFacebook, authenticateFromGoogle } from "../../../redux/reducers/authReducer";
import { waitPhoneConfirmation } from "../../../redux/reducers/customerReducer";
import { NavBar } from "../../appBars/navBar/navBar";

const inputValues = {
  firstName: '',
  lastName: '',
  birthDate: '',
  emailAddress: '',
  mobileNumber: '',
  gender: Gender.Unspecified,
  password: '',
  retypePsw: '',
}

export function Register() {

  const registrationProps = useLocation().state as RegistrationProps;

  if (registrationProps) {
    inputValues.firstName = registrationProps.firstName ?? '';
    inputValues.lastName = registrationProps.lastName ?? '';
    inputValues.emailAddress = registrationProps.emailAddress ?? ''
  }

  const isExternal = registrationProps !== null && registrationProps.externalLoginType !== null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customersClient: CustomerClient = new CustomerClient();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [textFieldValues, setTextFieldValues] = useState<RegisterFieldValues>(inputValues);
  const [showPsw, setShowPsw] = useState(false);
  const [showRetypedPsw, setShowRetypedPsw] = useState(false);
  const [rulesApproved, setRulesApproved] = useState(false);
  const [communicationApproved, setCommunicationApproved] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [openPrefixes, setOpenPrefixes] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const passwordsMatch: boolean = textFieldValues.retypePsw === textFieldValues.password;

  const { t } = useTranslation('account');
  const { t: tGender } = useTranslation('gender');

  useEffect(() => {
    validate();
  }, [textFieldValues, rulesApproved]);

  useEffect(() => {
    validatePassword();
  }, [textFieldValues.password, textFieldValues.retypePsw]);

  const validatePassword = () => {
    if (passwordsMatch) {
      resetErrorMessage();
      return;
    }

    if (textFieldValues.retypePsw !== "") {
      const msg: string = t('differentPasswords');
      setErrorMessage(msg);
    }
  }

  const resetErrorMessage = () => {
    setErrorMessage(null);
  }

  useEffect(() => {
    setCountryPrefix();
  }, [selectedCountry]);

  const handleFieldChange = (e: any) => {
    let { name, value } = e.target;

    if (name === 'emailAddress') {
      value = value?.trim();
    }

    const fieldValue = { [name]: value } as Pick<RegisterFieldValues, keyof RegisterFieldValues>;
    setTextFieldValues({
      ...textFieldValues,
      ...fieldValue
    });
  }

  const validate = () => {
    let validValues = false;
    if (isExternal) {
      const { ["retypePsw"]: remove, ...rest } = textFieldValues;
      delete rest.password;
      validValues = Object.values(rest).every(value => value !== "");
    }
    else {
      validValues = Object.values(textFieldValues).every(value => value !== "");
    }

    if (validValues && rulesApproved && isEmailValid && passwordsMatch) {
      setIsButtonDisabled(false);
    }
    else {
      setIsButtonDisabled(true);
    }
  }

  const setCountryPrefix = () => {
    const mobileNumberPrefix = { mobileNumber: selectedCountry?.phonePrefix } as Pick<RegisterFieldValues, keyof RegisterFieldValues>;
    setTextFieldValues({
      ...textFieldValues,
      ...mobileNumberPrefix
    });
    closePrefixes();
  }

  const toggleApproveRules = () => {
    setRulesApproved(!rulesApproved);
  }

  const toggleApproveCommunication = () => {
    setCommunicationApproved(!communicationApproved);
  }

  useEffect(() => {
    validateEmail(textFieldValues.emailAddress);
  }, [textFieldValues.emailAddress])

  const validateEmail = (mail: string) => {
    var pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (pattern.test(mail)) {
      setIsEmailValid(true);
    }
    else {
      setIsEmailValid(false);
    }
  }

  const registerUser = () => {
    setIsRegistering(true);

    if (isExternal) {
      let registrationRequest = {
        name: textFieldValues.firstName,
        surname: textFieldValues.lastName,
        phone: textFieldValues.mobileNumber,
        gender: getGenderType(textFieldValues.gender),
        communicationAgreed: communicationApproved,
        rulesAcceptedAgreed: rulesApproved,
        credentials: registrationProps.externalAuthRequest.accessToken,
        code: registrationProps.externalAuthRequest.code,
        state: registrationProps.externalAuthRequest.state,
        birthDate: textFieldValues.birthDate ? moment.utc(textFieldValues.birthDate) : null
      } as ExternalRegistrationRequest;

      customersClient.registerFromExternal(registrationRequest, registrationProps.externalLoginType)
        .then((response) => {
          if (response.errors) {
            var errKey: string = Object.keys(response.errors)[0];
            var errMsg = response.errors[errKey][0];
            setErrorMessage(errMsg);
            setIsRegistering(false);

            return;
          }

          authenticateFromExternal(registrationRequest.credentials, registrationRequest.state, registrationRequest.code);
        })
        .catch((err: AxiosResponse) => {
          var msg = getStatusError(err.status);
          setErrorMessage(msg);
          setIsRegistering(false);
          console.log(err);
        });
    }
    else {
      let registrationRequest = {
        name: textFieldValues.firstName,
        surname: textFieldValues.lastName,
        email: textFieldValues.emailAddress,
        phone: textFieldValues.mobileNumber,
        password: textFieldValues.password,
        gender: getGenderType(textFieldValues.gender),
        communicationAgreed: communicationApproved,
        rulesAcceptedAgreed: rulesApproved,
        birthDate: textFieldValues.birthDate ? moment.utc(textFieldValues.birthDate) : null
      } as RegistrationRequest;

      customersClient.register(registrationRequest)
        .then((response) => {
          if (response.errors) {
            var errKey: string = Object.keys(response.errors)[0];
            var errMsg = response.errors[errKey][0];
            setErrorMessage(errMsg);
            setIsRegistering(false);

            return;
          }

          dispatch(authenticate({ input: registrationRequest.phone, password: registrationRequest.password })).unwrap()
            .then(() => handleAuthenticationResponse())
            .catch((err) => {
              setIsRegistering(false);
              console.log(err);
            });;
        })
        .catch((err: AxiosResponse) => {
          var msg = getStatusError(err.status);
          setErrorMessage(msg);
          setIsRegistering(false);
          console.log(err);
        });
    }
  }

  const authenticateFromExternal = (credentials: string, state: string, code: string) => {
    let externalPromise: Promise<AuthenticationResponse>;
    let externalAuthRequest = {
      accessToken: credentials,
      state: state,
      code: code
    } as ExternalAuthenticationRequest;
    switch (registrationProps?.externalLoginType) {
      case ExternalAuthenticationType.Google:
        externalPromise = dispatch(authenticateFromGoogle(externalAuthRequest)).unwrap();
        break;
      case ExternalAuthenticationType.Apple:
        externalPromise = dispatch(authenticateFromApple(externalAuthRequest)).unwrap();
        break;
      case ExternalAuthenticationType.Facebook:
        externalPromise = dispatch(authenticateFromFacebook(externalAuthRequest)).unwrap();
        break;
      default:
        console.error("Invalid external login type: " + registrationProps?.externalLoginType);
        return;
    }

    externalPromise
      .then(() => handleAuthenticationResponse())
      .catch((err) => {
        setIsRegistering(false);
        console.log(err);
      });
  }

  const handleAuthenticationResponse = () => {
    resetErrorMessage();
    setIsRegistering(false);
    dispatch(waitPhoneConfirmation());
    navigate("/home?popup=confirm-phone");
  }

  const closeSuccess = () => {
    setShowSuccess(false);
  }

  const closePrefixes = () => {
    setOpenPrefixes(false);
  }

  const registerButton = () => {
    if (appStyleMode === AppStyle.Mobile) {
      return registerButtonMobile();
    }

    return registerButtonWebsite();
  }

  const registerButtonMobile = () => {
    return (
      <AppBar sx={{ backgroundColor: "transparent", top: "auto", bottom: "0px" }}>
        <RegisterButton sx={{ borderRadius: "4px" }} disabled={isButtonDisabled} variant="contained" onClick={() => registerUser()}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {isRegistering && <CircularProgress sx={{ height: "20px", width: "20px", mr: "5px", color: "paulini.blue" }} />}
            <Typography>{t('confirmRegistration')}</Typography>
          </Grid>
        </RegisterButton>
      </AppBar>
    );
  }

  const registerButtonWebsite = () => {
    return (
      <Box sx={{ display: "flex ", pb: "10px" }}>
        <Box sx={{ width: "300px", margin: "auto" }}>
          <LoadingButton
            disabled={isButtonDisabled}
            onClick={registerUser}
            loading={isRegistering}
            loadingIndicator={<CircularProgress sx={{ color: "paulini.blue" }} />}
            variant="contained"
          >
            <Box sx={{ color: "white" }}>{t('confirmRegistration')}</Box>
          </LoadingButton>
        </Box>
      </Box>
    );
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('registration')} />
      <BreadcrumbsBar to={"register"} />
      <Box sx={{ maxWidth: "1000px", width: "100%", backgroundColor: "white", borderRadius: "15px", color: "black", mt: "10px" }}>
        <List className={(appStyleMode === AppStyle.Mobile ? "register-list" : null)}
          sx={{
            padding: "0px 20px !important",
            overflow: "auto"
          }}>
          <InputField fieldName="firstName" label={t('name')} inputValue={textFieldValues.firstName} icon={<BorderColorIcon />} handleFieldChange={handleFieldChange} disableGutters={true} />
          <InputField fieldName="lastName" label={t('lastName')} inputValue={textFieldValues.lastName} icon={<BorderColorIcon />} handleFieldChange={handleFieldChange} disableGutters={true} />
          {
            !isExternal && (<InputField fieldName="emailAddress" label={t('email')} inputValue={textFieldValues.emailAddress} icon={<MailOutlineIcon />} handleFieldChange={handleFieldChange} disableGutters={true}
              endAdornment={
                <InputAdornment position="end">
                  {isEmailValid && textFieldValues.emailAddress !== "" && <CheckIcon sx={{ color: "paulini.green", height: "20px", width: "20px" }} />}
                  {!isEmailValid && textFieldValues.emailAddress !== "" && <CloseIcon sx={{ color: "paulini.error", height: "20px", width: "20px" }} />}
                </InputAdornment>} />)
          }

          <InputField fieldName="mobileNumber" label={t('phoneNumberShort')} placeholder="+370xxxxxxxx" inputValue={textFieldValues.mobileNumber} icon={<SmartphoneIcon />} disableGutters={true} handleFieldChange={handleFieldChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setOpenPrefixes(true)} edge="end">
                  {openPrefixes ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </InputAdornment>} />

          <InputField isGender={true} fieldName="gender" label={tGender('gender')} inputValue={textFieldValues.gender} icon={<WcIcon />} handleFieldChange={handleFieldChange} disableGutters={true} selectComp=
            {genderItems.map((option, index) => (
              <MenuItem key={index} value={option}>
                {getGenderLabel(option)}
              </MenuItem>
            ))} />

          <InputField isDate={true} fieldName="birthDate" label={t('birthDate')} inputValue={textFieldValues.birthDate} icon={<CakeIcon />} handleFieldChange={handleFieldChange} disableGutters={true} />

          {
            !isExternal && (<InputField fieldName="password" label={t('password')} inputValue={textFieldValues.password} icon={<LockIcon />} handleFieldChange={handleFieldChange} isPassword={!showPsw} disableGutters={true} endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPsw(!showPsw)} edge="end">
                  {showPsw ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>} />)
          }

          {
            !isExternal && (<InputField fieldName="retypePsw" label={t('retypeNewPassword')} inputValue={textFieldValues.retypePsw} icon={<LockIcon />} handleFieldChange={handleFieldChange} isPassword={!showRetypedPsw} disableGutters={true} endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowRetypedPsw(!showRetypedPsw)} edge="end">
                  {showRetypedPsw ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>} />)
          }

          <ListItem disableGutters>
            <ListItemIcon sx={{ color: "paulini.orange" }}>
              <CheckBox isChecked={communicationApproved} onChange={toggleApproveCommunication} />
            </ListItemIcon>
            <Typography onClick={toggleApproveCommunication}>{t('communicationApproved')}
            </Typography>
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon sx={{ color: "paulini.orange" }}>
              <CheckBox isChecked={rulesApproved} onChange={toggleApproveRules} />
            </ListItemIcon>
            <Box>
              <Typography display="inline" onClick={toggleApproveRules}>{t('agreeWithTermsPart1')}
              </Typography>
              <Typography display="inline">
                <Link component="button" underline="hover"
                  sx={{ verticalAlign: "baseline" }}
                  onClick={() => openRules()}>{t('agreeWithTermsPart2Link')}</Link>
              </Typography>
            </Box>
          </ListItem>

          <ErrorGrid sx={{ textAlign: "left" }}>
            {errorMessage && <Typography>{errorMessage}</Typography>}
            {textFieldValues.password && <>
              {(!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(textFieldValues.password) || textFieldValues.password.length < 8) && <Typography>{t('passwordRules')}</Typography>}
              {textFieldValues.password.length < 8 && <Typography>* {t('passwordRule8Chars')}</Typography>}
              {!/[A-Z]+/.test(textFieldValues.password) && <Typography>* {t('passwordRuleCapitalLetter')}</Typography>}
              {!/[a-z]+/.test(textFieldValues.password) && <Typography>* {t('passwordRuleLowerCase')}</Typography>}
              {!/[0-9]+/.test(textFieldValues.password) && <Typography>* {t('passwordRuleNumber')}</Typography>}
            </>}
          </ErrorGrid>
        </List >
        {registerButton()}
        <Success open={showSuccess} onClose={closeSuccess} />
        <PhonePrefixSelection open={openPrefixes} setSelectedCountry={setSelectedCountry} handleClose={closePrefixes} />
      </Box>
    </Grid >
  );
}